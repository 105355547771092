import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import Logos from "../../components/logos";
import OSSection from "../../components/other-services-section";
import OASection from "../../components/our-approach-section";
import PricingSection from "../../components/pricing-section";
import ReviewSection from "../../components/review-section";
import yellowOne from "../../images/1-yellow.svg";
import yellowTwo from "../../images/2-yellow.svg";
import yellowThree from "../../images/3-yellow.svg";

const ECommercePage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        mediaDetails {
          height
          width
        }
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      gatsbyImg: wpMediaItem(title: { eq: "E-commerce-websites-icon-3" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      shopifyImg: wpMediaItem(title: { eq: "E-commerce-websites-icon-2" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      onlineShopImg: wpMediaItem(title: { eq: "online-shop" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      weReccomentImg: wpMediaItem(title: { eq: "we-reccomend" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      luluImg: wpMediaItem(title: { eq: "lulu-image-new" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      genImg: wpMediaItem(title: { eq: "generates" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      wpSeoPage(title: { eq: "E-Commerce Dubai" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            mediaDetails {
              height
              width
            }
            link
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          opengraphTitle
          opengraphDescription
        }
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        link
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Dubai",
        item: {
          url: `${siteUrl}/dubai`,
          id: `${siteUrl}/dubai`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "E-commerce Websites",
        item: {
          url: `${siteUrl}/dubai/e-commerce-websites`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        {" "}
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/dubai/e-commerce-websites`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout dubai>
        <section>
          <Hero
            dubai
            button1="primary-link-dark"
            button1Text="Get in touch"
            button1Link="/dubai/contact-us"
            button1Var="white"
            button2={null}
            button3={null}
            backgroundImage={data.heroImg.gatsbyImage}
            title={`E-COMMERCE \n<span class="text-secondary">WEBSITES DUBAI</span>`}
            description="Discover new possibilities with your headless ecommerce solution."
          />
        </section>
        {/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#shopify"
								>
									Shopify and Gatsby
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#pricing"
								>
									Pricing
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
        <section id="intro" className="bg-light-grey py-5 py-lg-7">
          <Container>
            <Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
              <Col lg={5} className=" ">
                <h2 className="display-5 text-primary mb-5">
                  Is headless right for you?
                </h2>
                <p>
                  Are you seeking a modern approach to online selling? Look no
                  further than headless e-commerce. Rather than relying on an
                  outdated, all-in-one e-commerce store, opting for the headless
                  approach allows you to utilise specialised e-commerce tools to
                  enhance payments, inventory management, and user engagement,
                  meaning you can have complete customisation of the layout of
                  your online shop without any restrictions.
                </p>
                <p>
                  By separating the front-end from the back-end, allows you to
                  build your website as you wish with endless design
                  possibilities as well as having the ability to integrate any
                  back-end system.
                </p>
              </Col>
              <Col lg={7}>
                <div
                  style={{
                    overflow: "hidden",
                    borderRadius: "15px",
                    boxShadow: "0px 3px 99px #00000027",
                  }}
                >
                  <GatsbyImage
                    className="w-100"
                    image={data.onlineShopImg.gatsbyImage}
                    alt={data.onlineShopImg?.altText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
          <Container className="mb-8">
            <Row className="justify-content-between align-items-center">
              <Col md={6} lg={8}>
                <h2 className="display-2 ssp-bold text-white mb-lg-5">
                  BENEFITS OF
                  <span>
                    <br />{" "}
                    <span className="text-secondary">HEADLESS ECOMMERCE</span>
                  </span>
                </h2>
              </Col>
              {/* <Col md={6} lg={4} className="text-end">
								<StaticImage
									src="../../images/Google-Ads-Icon.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col> */}
            </Row>
            <Row className="gx-xl-9 gy-5 justify-content-center">
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={yellowOne} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Customisation</h3>
                  <p className="text-white">
                    Take control of the look and feel of your ecommerce store
                    without any template restraints.
                  </p>
                </div>
              </Col>
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={yellowTwo} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Speed</h3>
                  <p className="text-white">
                    Enhance the performance of your store with quicker page load
                    times.
                  </p>
                </div>
              </Col>
              <Col className="position-relative " md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute w-100 text-center h-100"
                >
                  <img src={yellowThree} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5 position-relative">
                  <h3 className="fs-1 text-white">Security</h3>
                  <p className="text-white">
                    Ensure your site is secure through the separation of your
                    websites front-end and back-end.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="bg-light-grey pb-5 py-lg-7">
          <Container>
            <Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
              <Col className="d-none d-lg-block" lg={5}>
                <div
                  style={{
                    overflow: "hidden",
                    borderRadius: "15px",
                    boxShadow: "0px 3px 99px #00000027",
                  }}
                >
                  <GatsbyImage
                    className="w-100"
                    image={data.weReccomentImg.gatsbyImage}
                    alt={data.weReccomentImg?.altText}
                  />
                </div>
              </Col>
              <Col lg={7} className=" ">
                <h2 className="display-5 mt-lg-5 mt-lg-0 text-primary mb-5">
                  Why we trust Shopify
                </h2>
                <p>
                  Shopify is a platform exclusively designed for e-commerce
                  websites, providing a user-friendly interface, robust
                  security, and an active community that enables your online
                  shop to scale along with your business.
                </p>
                <p>
                  Shopify makes it easy for you to manage and track your
                  products, securely process payments, and utilise a variety of
                  features to enhance your customers' shopping experience.
                </p>
                <Button
                  className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
                  variant="primary"
                  as={Link}
                  to="/dubai/contact-us"
                >
                  GET IN TOUCH
                </Button>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="bg-light-grey py-5 py-lg-7">
          <Container>
            <Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
              <Col lg={6} className=" ">
                <h2 className="display-5 text-primary mb-5">
                  Exceed your ecommerce goals
                </h2>
                <p>
                  At RJM Digital we have years of experience working with
                  various e-commerce platforms and tools and will guide you in
                  selecting the most suitable ones for your business needs. Our
                  team knows the ins and outs of user experience, front-end
                  development, and API integration, which allows us to provide
                  you with valuable assistance to help you scale and make your
                  ecommerce store more robust. We will take care of all the
                  technical aspects involved in setting up your new headless
                  e-commerce site, ensuring it represents your brand and
                  delivers a great user experience on any device. At RJM
                  Digital, we offer ongoing support and maintenance, ensuring
                  your online store is always up-to-date and performing at its
                  best.
                </p>
              </Col>
              <Col lg={6}>
                <div
                  style={{
                    overflow: "hidden",
                    borderRadius: "15px",
                    boxShadow: "0px 3px 99px #00000027",
                  }}
                >
                  <GatsbyImage
                    className="w-100"
                    image={data.genImg.gatsbyImage}
                    alt={data.genImg?.altText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section
          id="shopify"
          style={{ overflow: "hidden" }}
          className="bg-light-grey position-relative pt-5 pt-lg-7"
        >
          <div className="position-relative bg-dark-grey">
            <Container className="pb-10 pt-7">
              <Row className="align-items-center mb-5">
                <Col lg={6} className=" ">
                  <h2 className=" display-4 text-white ssp-bold mb-4">
                    Why RJM Digital like
                    <br />
                    <span className="text-shopify">Shopify</span> &{" "}
                    <span className="text-gatsby">GatsbyJS</span>
                  </h2>
                  <p className="text-white">
                    To create a static e-commerce website that is easily
                    manageable and scalable as your sales grow, Shopify and
                    GatsbyJS are the ideal tools. They provide a user-friendly
                    system that allows for reliable expansion of your online
                    store.
                  </p>
                  <p className="text-white">
                    Shopify offers an excellent user-friendly inventory
                    management solution with advanced ecommerce features.
                  </p>
                  <p className="text-white">
                    GatsbyJS enables you to achieve quicker loading speeds,
                    greater security, and complete customisation of the
                    appearance of your online store.
                  </p>
                </Col>
                <Col lg={6} className=" ">
                  <div className="position-absolute">
                    <GatsbyImage
                      className="w-70"
                      image={data.gatsbyImg.gatsbyImage}
                      alt={data.gatsbyImg?.altText}
                    />
                  </div>
                  <div
                    style={{ top: "8rem", right: "-13rem" }}
                    className="position-relative d-none d-md-block "
                  >
                    {" "}
                    <GatsbyImage
                      className="w-70"
                      image={data.shopifyImg.gatsbyImage}
                      alt={data.shopifyImg?.altText}
                    />
                  </div>
                  <div
                    style={{ top: "8rem", right: "-7rem" }}
                    className="position-relative  d-md-none "
                  >
                    {" "}
                    <GatsbyImage
                      className="w-70"
                      image={data.shopifyImg.gatsbyImage}
                      alt={data.shopifyImg?.altText}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <div id="approach">
          {" "}
          <OASection
            imgHeight="60rem"
            image={data.luluImg.gatsbyImage}
            imageAlt={data.luluImg?.altText}
            launch="Once we've meticulously gone through every aspect of your website to ensure a seamless user experience, the RJM Digital team will be all set to launch your new headless ecommerce website."
            build="We are experts in ecommerce and will use the most appropriate technologies to meet your business needs. You will be provided with access to our test server, allowing you to oversee the development of your website. Before the launch, we will make sure you're completely happy with the final design."
            design="Following the initial consultation, we will start the design process for your online store, ensuring it's customised to meet your specific needs, enhances user experience and fuels revenue growth. You will have the opportunity to make any adjustments until you are completely satisfied during this phase."
            brief="RJM Digital makes the creation of your headless ecommerce website a seamless and stress-free experience. Our skilled team will collaborate with you throughout the entire process to create an eye-catching and effective online store that serves as a valuable tool for driving sales."
          />
        </div>

        {/* <div id="pricing">
					<PricingSection
						customText="Have a custom e-commerce website project for your Dubai business?"
						text1="For small UAE businesses and start-ups looking for their first e-commerce website"
						text2="For ambitious UAE companies looking to increase the amount of leads to their e-commerce website"
						text3="For established UAE businesses requiring an advanced e-commerce solution"
						dubai
						mEssPrice="AED4995"
						mPrePrice="AED6500"
						mProPrice="AED10000"
						mPmEssPrice="AED495"
						mPmPrePrice="AED695"
						mPmProPrice="AED995"
						oEssPrice="AED14995"
						oPrePrice="AED19995"
						oProPrice="AED29995"
						oCpEssPrice="AED300"
						oCpPrePrice="AED300"
						oCpProPrice="AED300"
					/>
				</div> */}
        <Logos />
        {/* <section className="py-5 py-lg-7 bg-light-grey">
					<PortfolioSection page="Home" />
				</section> */}
        <section className="pt-5 pt-lg-7  bg-light-grey">
          <Container>
            <Row id="form">
              <Col className="text-center">
                {" "}
                <h2 className="display-5 text-primary mb-3">
                  Talk to an expert
                </h2>
                <p>
                  Book a time and day that works for you through our online
                  calendar to discuss your e-commerce project.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
          <ReviewSection page="E-commerce" />
        </section> */}
        <div id="faq">
          {" "}
          <Faq page="E-commerce" />
        </div>
        <OSSection
          dubai
          link3="/dubai/branding"
          text3="BRANDING"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1?.altText}
        />
      </Layout>
    </>
  );
};

export default ECommercePage;
